import React, { useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Button, Col, Row } from "reactstrap";
import { Formik } from "formik";
import { setToast } from "../../../../app/rootReducer";
import { useDispatch } from "react-redux";
import QButton from "../../../../common/components/QButton";
import { AccountInterface } from "../../../../interfaces/private/AccountInterface";
import UserApi from "../../../../common/api/functions/User";

interface Props {
    model: AccountInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setAccount: Function,
}

const BodyAccountComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc, setAccount }) => {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...model }}
                onSubmit={(values: any, { setFieldError }) => {
                    setLoadingFunc(true);
                    UserApi.ChangePassword(values).then(async (response: ResponseInterface | any) => {

                        if (response.status === config.STATUS_CODE.OK) {
                            await setOpen(false);
                            await setLoadingFunc(true);
                            await setAccount(response?.data);
                            await setLoadingFunc(false);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    },response => {
                        if(typeof response.data.message === "string"){
                            // setMessageErr(response?.data?.message);
                            dispatch(setToast({
                                message: response.data?.message,
                                type: config.TOAST_TYPE.ERROR,
                            }))
                        }else{
                            let message = response.data.message;
                            for (let key in message){
                                setFieldError(key, message[key][0]);
                            }
                        }

                    })

                }}

            >
                {formik => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QTextBox
                                        type={'password'}
                                        label={'Current Password'}
                                        value={formik.values.current_password}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('current_password', value)
                                        }}
                                        error={formik.touched?.current_password && formik.errors?.current_password}


                                    />
                                    <QTextBox
                                        type={'password'}
                                        label={'Password'}
                                        value={formik.values.new_password}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('new_password', value)
                                        }}
                                        error={formik.touched?.new_password && formik.errors?.new_password}

                                    />
                                    <QTextBox
                                        type={'password'}
                                        label={'New Password Confirm'}
                                        value={formik.values.new_password_confirm}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('new_password_confirm', value)
                                        }}
                                        error={formik.touched?.new_password_confirm && formik.errors?.new_password_confirm}

                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>

                                        <QButton
                                            type={'default'}
                                            onClick={() => { setOpen(0) }}
                                            content={'Cancel'}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit() }}
                                            content={"Submit"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyAccountComponent
