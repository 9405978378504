import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseExtLayout, UseSecond01Layout, UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";
import { config } from "../../../config";
import { QHelpers } from "../../../common/helpers";
import {useHistory, useLocation} from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import login from "../../../assets/images/login.png";
import createAccount from "../../../assets/images/create-account.png";
import economicToken from "../../../assets/images/economic-token.png";
import addTokenApp from "../../../assets/images/add-token-app.png";
import forgotPassword from "../../../assets/images/forgot-password.png";
import verifyEmail from "../../../assets/images/verify-email.png";
import verifyForgotPassword from "../../../assets/images/verify-forgot-password.png";
import forgotPasswordClient from "../../../assets/images/forgot-password-client.png";
import resetPassword from "../../../assets/images/reset-password.png";
import orders from "../../../assets/images/orders.png";
import confirm from "../../../assets/images/confirm.png";
import loginSuccessful from "../../../assets/images/loginSuccessful.png";
import layout_1 from "../../../assets/images/layout_1.jpg";
import layout_2 from "../../../assets/images/layout_2.jpg";
import layout_3 from "../../../assets/images/layout_3.jpg";
import layout_4 from "../../../assets/images/layout_4.jpg";
import layout_5 from "../../../assets/images/layout_5.jpg";
import layout_6 from "../../../assets/images/layout_6.jpg";
import layout_7 from "../../../assets/images/layout_7.jpg";
import layout_8 from "../../../assets/images/layout_8.jpg";
import layout_9 from "../../../assets/images/layout_9.jpg";
import layout_10 from "../../../assets/images/layout_10.jpg";
import layout_11 from "../../../assets/images/layout_11.jpg";
import layout_12 from "../../../assets/images/layout_12.jpg";

import layout_13 from "../../../assets/images/h1.png";
import layout_14 from "../../../assets/images/h2.jpg";
import layout_15 from "../../../assets/images/h3.png";

import vipps_01 from "../../../assets/images/document/setup-vipps-01.png";
import vipps_02 from "../../../assets/images/document/setup-vipps-02.png";
import vipps_03 from "../../../assets/images/document/setup-vipps-03.png";
import vipps_04 from "../../../assets/images/document/setup-vipps-04.png";
import vipps_05 from "../../../assets/images/document/setup-vipps-05.png";
import ImageZoomModal from "../../../common/components/Genneral/ImageZoomModal";

export function Document() {
    UseSecond01Layout();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            setTimeout(()=>{
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            },500)
        }
    }, [location]);

    return (
        <div className={'row pt-3 df-document'}>
            <div className={"col-md-3 side-bar-document"}>
                <ul>
                    <li><a href="#create-new-account">I. No Account at 2app MobilePay</a></li>
                    <li> <a href="#first-login">II. Account at 2app MobilePay</a></li>
                    <li> <a href="#forgot-password">III. Create webhook in Economic</a></li>
                    <li><a href="#shipping-management">IV. Login information</a></li>
                    <li><a href="#layout">V. Design and layout PDF e-conomic's invoice</a></li>
                    <li><a href="#mobilepay2economic">VI. MobilePay til e-conomic</a></li>
                    <li><a href="#grantAccessVippsMobilePay">VII. Setting Up Vipps MobilePay for 2app</a></li>
                </ul>
            </div>
            <div className={"col-md-8"}>
                <h1 className="text-danger text-center">2app MobilePay</h1>
                <br />
                <section id="create-new-account">

                    <h5 className="text-danger">I. No Account at 2app MobilePay</h5>
                    <p className="text-center"><iframe width="900" height="400" src="https://www.youtube.com/embed/o1QpmDzdlcg?si=B9WC2XxwEB1DwzZD"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </p>
                    <h5 className="padding-left-50">1. Add app from Economic Market: Click button Add App</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={login}
                            modalSrc={login} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">2. Before creating an account at 2app MobilePay, you need to confirm. </h5>

                    <p className="text-center"><b>Input information and then click button "Create Account", system will send mail verify new account. </b></p>
                    <div className="text-center">
                        <ImageZoomModal
                            src={createAccount}
                            modalSrc={createAccount} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">3. Update information for the first time</h5>
                    <p className="text-center">
                        <iframe width="900" height="400"
                                src="https://www.youtube.com/embed/fiWs5Mns9oU?si=g-Sp0KoHYV92I_ix"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </p>
                    <div className="text-center">
                        <ImageZoomModal
                            src={verifyEmail}
                            modalSrc={verifyEmail} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">4. After updating the information, click Create account </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={confirm}
                            modalSrc={confirm} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">5. Login successful</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={loginSuccessful}
                            modalSrc={loginSuccessful} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>
                <section id="first-login">
                    <h5 className="text-danger">II. Account at 2app MobilePay</h5>
                    <h5 className="padding-left-50">1. Add app from Economic Market: Click button Add App </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={economicToken}
                            modalSrc={economicToken} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">2. Already have an account at 2app MobilePay, the system will display log information after Add App </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={addTokenApp}
                            modalSrc={addTokenApp} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>
                <section id="forgot-password">
                    <h5 className="text-danger">III.Create webhook in Economic</h5>
                    <p className="text-center">
                        <iframe width="900" height="400"
                                src="https://www.youtube.com/embed/Vr21zXKefLA?si=N423W4QKPTmRx4Xl"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </p>
                    <h5 className="padding-left-50">1. 2App MobilePay: Copy information of Webhook</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={forgotPassword}
                            modalSrc={forgotPassword} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">2. Economic: Paste the information of the Webhook into the create form</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={forgotPasswordClient}
                            modalSrc={forgotPasswordClient} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>
                <section id="shipping-management">
                    <h5 className="text-danger">IV. Login information</h5>
                    <div className="padding-left-50">
                        <h5>Email: mail of customer</h5>
                        <h5>Password: (CRV number)</h5>
                    </div>
                    <div className="text-center">
                        <ImageZoomModal
                            src={orders}
                            modalSrc={orders} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>
                <section id="layout">
                    <h5 className="text-danger">V. Design and layout PDF e-conomic's invoice</h5>
                    <p className="text-center">
                        <iframe width="900" height="400"
                                src="https://www.youtube.com/embed/t-Rzw4vv_Zc?si=XHITL8QAg8E_r5pc"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </p>
                    <h5 className="padding-left-50">1. Design and layout in economic</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_1}
                            modalSrc={layout_1} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">2. Click New template collection and then input information of Template. </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_2}
                            modalSrc={layout_2} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">3. After creating a name for Template(Layout) we will start creating each layout for each type included in economic. </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_3}
                            modalSrc={layout_3} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">4. Create New Element for Template Invoice</h5>
                    <h5 className="padding-left-70">i. Choose Type Element</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_4}
                            modalSrc={layout_4} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">ii.	Input Text for Element</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_5}
                            modalSrc={layout_5} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">iii. Information for MobilePay’s Payment Link</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_6}
                            modalSrc={layout_6} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-50">5. Letterheads for Template(Layout)</h5>
                    <h5 className="padding-left-70">i. Active Letterhead</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_7}
                            modalSrc={layout_7} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">ii.	Click on Letterheads button </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_8}
                            modalSrc={layout_8} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">iii. Create template for Letterhead. </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_9}
                            modalSrc={layout_9} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">iv.	After click Layout, create Element </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_10}
                            modalSrc={layout_10} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">v.	Choose Type of Element  </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_11}
                            modalSrc={layout_11} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <h5 className="padding-left-70">vi.	Input text for Element </h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_12}
                            modalSrc={layout_12} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>


                <section id="mobilepay2economic">
                    <h5 className="text-danger">VI. Design and layout PDF e-conomic's invoice</h5>
                    <h5 className="padding-left-50">Quick and easy to get a payment link in your invoices.</h5>
                    <div className="text-center">
                        <ImageZoomModal
                            src={layout_13}
                            modalSrc={layout_13} // Đường dẫn đến hình ảnh lớn
                        />

                        <ImageZoomModal
                            src={layout_14}
                            modalSrc={layout_14} // Đường dẫn đến hình ảnh lớn
                        />

                        <ImageZoomModal
                            src={layout_15}
                            modalSrc={layout_15} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                </section>

                <section id="grantAccessVippsMobilePay">
                    <h5 className="text-danger">VII. Setting Up Vipps MobilePay for 2app</h5>
                    <h5 className="padding-left-50">This guide outlines the essential steps to set up Vipps MobilePay for 2app.</h5>
                    <br />
                    <h5 className="padding-left-50">Step 1: Log in to the MobilePay Merchant Portal</h5>
                    <div className={'padding-left-50 text-left'}>
                        Access the portal at: <a href={'https://portal.vippsmobilepay.com/'} target={'_blank'}>https://portal.vippsmobilepay.com</a>
                    </div>
                    <br />
                    <h5 className="padding-left-50">Step 2: Navigate to “Reports”</h5>

                    <div className={'padding-left-50 text-left'}>
                        <ImageZoomModal
                            src={vipps_01}
                            modalSrc={vipps_01} // Đường dẫn đến hình ảnh lớn
                        />
                        Click on “Accounting partners.”
                        <br />
                        <ImageZoomModal
                            src={vipps_02}
                            modalSrc={vipps_02} // Đường dẫn đến hình ảnh lớn
                        />
                        <br />
                        <br />
                        Select “Edit partner access.”
                        <ImageZoomModal
                            src={vipps_03}
                            modalSrc={vipps_03} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <br />
                    <h5 className="padding-left-50">Step 3: Grant Access to 2app</h5>
                    <div className={'padding-left-50 text-left'}>
                        In the search field under Accounting partner, type “2app”.
                        <br />
                        <ImageZoomModal
                            src={vipps_04}
                            modalSrc={vipps_04} // Đường dẫn đến hình ảnh lớn
                        />
                        <br />
                        <br />
                        Check the box next to 2app and click “Save.”
                        <br />
                        <ImageZoomModal
                            src={vipps_05}
                            modalSrc={vipps_05} // Đường dẫn đến hình ảnh lớn
                        />
                    </div>
                    <br />
                    <h5 className="padding-left-50">Step 4: Contact 2app Support</h5>
                    <div className={'padding-left-50 text-left'}>

                        Once you have completed these steps, 2app will have access to connect the module. Since the module cannot be connected manually, please reach out to 2app support at [info@2app.dk] for further assistance.
                    </div>
                    <br />
                    <br />
                </section>
            </div>
        </div>
    )
}

// @ts-ignore
export default React.memo(Document);
