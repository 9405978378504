import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {UseMainLayout} from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import BodyModalIntegrationComponent from "./components/BodyModalIntegrationComponent";
import {PaymentInterface} from "../../../interfaces/private/PaymentInterface";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import PaymentApi from "../../../common/api/functions/Payment";
import {config} from "../../../config";
import QSelectPerPage from "../../../common/components/Genneral/QSelectPerPage";
import QLoading from "../../../common/components/QLoading";
import QHeaderTable from "../../../common/components/Genneral/QHeaderTable";
import {PaymentConfig} from "./config";
import QPagination from "../../../common/components/QPaging";
import QSearch from "../../../common/components/Genneral/QSearch";
import BodyModalSettingComponent from "./components/BodyModalSettingComponent";
import SetupApi from "../../../common/api/functions/Setup";
import {PaymentSetupInterface} from "../../../interfaces/private/SetupInterface";
import payment from "../../../common/api/functions/Payment";
import BodyModalPaymentDetailComponent from "./components/BodyModalPaymentDetailComponent";
import BodyModalAssignLedgerComponent from "./components/BodyModalAssignLedgerComponent";
import ContentOverlayComponent from "./components/ContentOverlayComponent";

const Payment = () => {
    const {t, i18n} = useTranslation();

    const [openInitSetup, setOpenInitSetup] = useState(false);
    const [openModalAssignLedger, setOpenModalAssignLedger] = useState(false);
    const [openModalPaymentSetup, setOpenModalPaymentSetup] = useState(false);
    const [openModalIntegration, setOpenModalIntegration] = useState(false);
    const [openModalLogDetail, setOpenModalLogDetail] = useState(false);
    const [progress, setProgress] = useState(0);
    const [payments, setPayments] = useState<Array<PaymentInterface>>([]);
    const [paymentSetup, setPaymentSetup] = useState<PaymentSetupInterface>({
        eco_cashbook_name: "",
        eco_cashbook_number: "",
        eco_credit_account: "",
        eco_debit_account: "",
        eco_diff_currency_account: "",
        eco_fee_account: "",
        last_run: "",
        ledgers: "",
        matching_invoice: false
    });
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination, type: 'payment'
    })
    const [loading, setLoading] = useState(true);
    const [loadingLogDetail, setLoadingLogDetail] = useState(false);
    const [loadingSetting, setLoadingSetting] = useState(false);
    const [paymentDetail, setPaymentDetail] = useState<PaymentInterface>({
        _id: 0,
        amount: 0,
        balanceAfter: "",
        balanceBefore: "",
        created_at: "",
        currency: "",
        data_log: "",
        eco_invoice_no: 0,
        entryType: "",
        ledgerDate: "",
        ledger_id: 0,
        maskedPhoneNo: "",
        data_message: "",
        message: "",
        name: "",
        pspReference: "",
        recipientHandle: "",
        reference: "",
        retry_count: 0,
        status: "",
        time: "",
        updated_at: "",
        time_ago: ""
    });
    UseMainLayout();

    useEffect(() => {
        if (loading) {
            loadLog().then(async () => {
                await setLoading(false);
            })
        }
    }, [loading]);

    useEffect(() => {
        loadSetting();
    }, [])

    useEffect(() => {
        if(paymentSetup.ledgers == "-1" ){
            setOpenModalAssignLedger(true);
        }else{
            if(paymentSetup.eco_cashbook_number == "-1" ){
                setOpenInitSetup(true);
            }else{
                setOpenInitSetup(false);
            }
            setOpenModalAssignLedger(false);

        }

    }, [paymentSetup.ledgers,paymentSetup.eco_cashbook_number])



    const loadSetting = async () => {
        setLoadingSetting(true);
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    const data = response?.data.data || []
                    setPaymentSetup({
                            eco_cashbook_name: data?.eco_cashbook_name,
                            eco_cashbook_number: data?.eco_cashbook_number,
                            eco_credit_account: data?.eco_credit_account,
                            eco_debit_account: data?.eco_debit_account,
                            eco_diff_currency_account: data?.eco_diff_currency_account,
                            eco_fee_account: data?.eco_fee_account,
                            matching_invoice: data?.matching_invoice,
                            last_run: data?.last_run,
                            ledgers: data?.ledgers,
                        }
                    )
                }
            })

        } catch (err) {
        } finally {
            await setLoadingSetting(false);
        }
    };

    const loadLog = async () => {
        try {
            await PaymentApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    response = response.data.data;
                    setPayments(
                        response?.data || []
                    )
                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        } finally {
            await setLoading(false);
        }
    }

    const callIntegration = async (params?: any) => {
        setProgress(80);
        return PaymentApi.syncPayment(params).then(async (response: ResponseInterface | any) => {
            setProgress(100);
            await loadLog();
        });
    };

    const handleViewLogDetail = (_id: number) => {
        setLoadingLogDetail(true);
        setOpenModalLogDetail(true);
        // LogApi.getDetail(_id).then(async (response: PaymentInterface | any) => {
        //     if (response?.data?.data) {
        //         // setLog(response?.data?.data);
        //         setTimeout(() => {
        //             setLoadingLogDetail(false);
        //         }, 3000);
        //     }
        // })
    };
    // Styles


    return (
        <React.Fragment>
            <ContentOverlayComponent open={openModalAssignLedger} />
            <QModal
                open={openModalIntegration}
                setOpen={setOpenModalIntegration}
                headerContent={'Get payments'}
                bodyContent={(
                    <BodyModalIntegrationComponent
                        showForceImport={true}
                        setOpen={setOpenModalIntegration}
                        open={openModalIntegration}
                        callIntegration={callIntegration}
                        progress={progress}
                    />
                )}
                onClosed={() => setProgress(0)}
                // onOpened={()=>setProgress(0)}

            />
            <QModal
                alwayShow={openInitSetup}
                keyboard={openInitSetup}
                backdrop={openInitSetup?'static':false}
                size={'xl'}
                open={openModalPaymentSetup || openInitSetup}
                setOpen={setOpenModalLogDetail}
                headerContent={'Detail'}
                onClosed={() => {
                }}
                bodyContent={(
                    <BodyModalSettingComponent
                        setOpen={setOpenModalPaymentSetup}
                        // open={openModalIntegration}
                        // setLoading={setLoadingLogDetail}
                        setLoading={setLoadingSetting}
                        // loading={loadingLogDetail}
                        model={paymentSetup}
                        setPaymentSetup={setPaymentSetup}/>
                )}

            />

            <QModal
                size={'xl'}
                open={openModalLogDetail}
                setOpen={setOpenModalLogDetail}
                headerContent={'Detail'}
                // onClosed={()}
                bodyContent={(
                    <BodyModalPaymentDetailComponent
                        setOpen={setOpenModalLogDetail}
                        open={openModalIntegration}
                        setLoading={setLoadingLogDetail}
                        loading={loadingLogDetail}
                        log={paymentDetail}
                    />
                )}

            />

            <div className="col-md-12">

                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Payments</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a href="#" className="btn btn-sm  bg-orange-zp ml-3"
                                       onClick={() => setOpenModalIntegration(true)}><span><i
                                        className="lab la-telegram mr-1"></i> Manual get payments</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                            <div className="row">
                                <div className="col-md-8">
                                    <h5 className="mb-2">Setting</h5>

                                </div>
                                <div className="col-md-4 text-end">
                                    <a className={""} href="#" data-bs-toggle="modal"
                                       data-bs-target="#showModalBusinessDetail"><i
                                        className="las la-pen fs-24 cl-br-ams ml-2"
                                        onClick={() => {
                                            setOpenModalPaymentSetup(true);
                                        }}
                                    /></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    <span><strong>Cashbook</strong>:</span> {paymentSetup.eco_cashbook_name ?? 'n/a'}</div>
                                <div className="col-md-4 mb-2">
                                    <span><strong>Debit account</strong>:</span> {paymentSetup.eco_debit_account ?? 'n/a'}</div>
              {/*                  <div className="col-md-4 mb-2">
                                    <span><strong>Credit account</strong>:</span> {paymentSetup.eco_credit_account ?? 'n/a'}</div>*/}
                                <div className="col-md-4 mb-2">
                                    <span><strong>Fee account</strong>:</span> {paymentSetup.eco_fee_account ?? 'n/a'}</div>
                                <div className="col-md-4 mb-2">
                                    <span><strong>Difference currency account</strong>:</span> {paymentSetup.eco_diff_currency_account ?? 'n/a'}
                                </div>
                              {/*  <div className="col-md-4 mb-2">
                                    <span><strong>Matching invoice</strong>:</span> {paymentSetup.matching_invoice ? 'Yes' : 'No'}</div>*/}
                                <div className="col-md-4 mb-2">
                                    <span><strong>Ledgers </strong>:</span> {paymentSetup.ledgers ?? 'n/a'}
                                </div>
                                <div className="col-md-4 mb-2">
                                    <span><strong >Last run</strong>: </span>{(moment(paymentSetup.last_run)).isValid() && moment(paymentSetup.last_run).format('DD.MM.YY') || 'n/a'}</div>

                            </div>
                            {/*<div className="row">

                            </div>*/}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination}
                                                setLoading={setLoading}/>
                            </div>
                            <QLoading loading={loading || loadingSetting || loadingLogDetail}/>
                            <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                <QHeaderTable
                                    paging={pagination}
                                    setPaging={async (p: any) => {
                                        await setPagination(p);
                                        await setLoading(true);
                                    }}
                                    headers={PaymentConfig.headers(t)}
                                    funcChecked={() => {
                                    }}
                                />
                                <tbody className="list form-check-all">
                                {payments?.map((payment: PaymentInterface, index: number) => {
                                    // let checkedLogIndex: any | LogInterface = logChecked.arrOrder?.findIndex((o: number) => o === log._id);
                                    return (
                                        <tr key={index}>
                                            {/*<th scope="row">*/}
                                            {/*    <div className="form-check">*/}
                                            {/*        <input*/}
                                            {/*            className="form-check-input chk_child"*/}
                                            {/*            type="checkbox"*/}
                                            {/*            name="chk_child"*/}
                                            {/*            // checked={checkedLogIndex > -1}*/}
                                            {/*            defaultValue="option1"*/}
                                            {/*            onClick={(event: any) => {*/}

                                            {/*            }}*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*</th>*/}
                                            <td className={'text-left'}> {payment.pspReference}</td>
                                            <td> {payment.name}</td>
                                            <td> {payment.message}</td>
                                            <td className={'text-right'}> {payment.amount / 100}</td>
                                            <td className={'text-center'}>{(moment(payment.time)).isValid() && moment(payment.time).format('DD.MM.YY')}</td>
                                            <td className={'text-right'}> {payment.eco_invoice_no}</td>
                                            <td>  {payment.data_message?.substring(0, 100)}</td>
                                            <td className={'text-center'}>
                                              <span className={{
                                                  success: "text-success",
                                                  failed: "text-danger",
                                                  notice: "text-warning"
                                              }[payment.status] || "text-secondary"}>
                                                {payment.status}
                                              </span>
                                            </td>
                                            <td>  {payment.time_ago}</td>
                                            <td className="text-end">
                                                <a href={'#'} onClick={(e) => {
                                                    e.preventDefault();
                                                    // setLoadingLogDetail(true);
                                                    setOpenModalLogDetail(true);
                                                    setPaymentDetail(payment);
                                                    // handleViewLogDetail(payment._id);
                                                }}>
                                                    <i className="las la-chevron-right fs-16 cl-br-ams ml-2"/>
                                                </a>

                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default React.memo(Payment);
