import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import {Col, FormGroup, Input, Row} from "reactstrap";
import {Formik, useFormik} from "formik";
import QButton from "../../../../common/components/QButton";
import {useDispatch} from "react-redux";
import {setToast} from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import {PaymentSetupInterface, SetupInterface} from "../../../../interfaces/private/SetupInterface";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AccountInterface, CashbookInterface} from "../../../../interfaces/CommonInterface";
import QSelect from "../../../../common/components/QSelect";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import * as yup from "yup";


interface Props {
    model: PaymentSetupInterface,
    setOpen: Function,
    setPaymentSetup: Function,
    setLoading: Function,
}

const BodyModalSettingComponent: React.FC<Props> = ({model,setPaymentSetup, setOpen, setLoading}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const [accounts, setAccounts] = useState<AccountInterface[]>([]);
    const [cashbooks, setCashbooks] = useState<CashbookInterface[]>([]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            loadAccount(),
            loadCashbook(),
        ]).then(([accountData, cashbookData]) => {
            // console.log("Account Data:", accountData);
            // console.log("Cashbook Data:", cashbookData);
        })
            .catch((err) => {
                console.error("Error in Promise.all:", err);
            }).finally(() => {
            setLoading(false);
        });

    }, [])

    const loadAccount = async () => {
        try {
            const response = await SetupApi.getEcoSetup('accounts');
            if (response?.status === config.STATUS_CODE.OK) {
                setAccounts(
                    response?.data?.data || []
                )
            }
            return response?.data?.data || [];

        } catch (err) {
            console.error("Error loading account:", err);
            return null;
        }
    }


    const loadCashbook = async () => {
        try {
            const response = await SetupApi.getEcoSetup('journals');
            if (response?.status === config.STATUS_CODE.OK) {
                setCashbooks(
                    response?.data?.data || []
                )
            }
            return response?.data?.data || [];

        } catch (err) {
            console.error("Error loading cashbook:", err);
            return null;
        }
    }

    const formik = useFormik({
        initialValues:{...model},
        enableReinitialize:true,
        validationSchema:yup.object().shape({
            eco_cashbook_number: yup.string().required("Please choose cashbook").nullable(),
            eco_debit_account: yup.string().required("Please choose debit account").nullable(),
            // eco_credit_account: yup.string().when("matching_invoice", {
            //     is: false, // Chỉ validate nếu economic_payment_matching_invoice = false
            //     then: yup.string().required("Please choose credit account"),
            //     otherwise: yup.string().nullable(),
            // }),
            eco_fee_account: yup.string().required("Please choose fee account").nullable(),
            // matching_invoice: yup.string().required("Please choose difference currency account"),
        }),
        onSubmit: async (values: any) => {
            SetupApi.update(values).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    setPaymentSetup(response?.data.data)

                    await setOpen(false);
                    await dispatch(setToast({
                        message: "Update success",
                        type: config.TOAST_TYPE.SUCCESS
                    }))
                }
            })
        }
    });
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <QSelect
                        label={'Cashbook'}
                        value={parseInt(formik.values.eco_cashbook_number)}
                        onChange={(option:SelectOptionInterface) => {
                            formik.setFieldValue('eco_cashbook_number',option.value);
                            formik.setFieldValue('eco_cashbook_name',cashbooks?.find(s => s.journalNumber === Number(option.value))?.name);
                        }}
                        options={cashbooks.map((a:CashbookInterface)=>({label:a.name,value:a.journalNumber}))}
                        error={formik.errors?.eco_cashbook_number}
                    />

                </Col>
                <Col>
                    <QSelect
                        label={'Debit account'}
                        value={parseInt(formik.values.eco_debit_account)}
                        onChange={(option: SelectOptionInterface) => {
                            formik.setFieldValue('eco_debit_account', option.value);
                        }}
                        options={accounts.map((a: AccountInterface) => ({
                            label: a.accountNumber + ' - ' + a.name,
                            value: a.accountNumber
                        }))}
                        error={formik.errors?.eco_debit_account}
                    />

                </Col>
                {/*<Col>
                    <QSelect
                        label={'Credit account'}
                        value={parseInt(formik.values.eco_credit_account)}
                        onChange={(option: SelectOptionInterface) => {
                            formik.setFieldValue('eco_credit_account', option.value);
                        }}
                        options={accounts.map((a: AccountInterface) => ({
                            label: a.accountNumber + ' - ' + a.name,
                            value: a.accountNumber
                        }))}
                        error={formik.errors?.eco_credit_account}
                    />

                </Col>*/}
                <Col>
                    <QSelect
                        label={'Fee account'}
                        value={parseInt(formik.values.eco_fee_account)}
                        onChange={(option: SelectOptionInterface) => {
                            formik.setFieldValue('eco_fee_account', option.value);
                        }}
                        options={accounts.map((a: AccountInterface) => ({
                            label: a.accountNumber + ' - ' + a.name,
                            value: a.accountNumber
                        }))}
                        error={formik.errors?.eco_fee_account}
                    />

                </Col>
                <Col>
                    <QSelect
                        label={'Currency account'}
                        value={parseInt(formik.values.eco_diff_currency_account)}
                        onChange={(option: SelectOptionInterface) => {
                            formik.setFieldValue('eco_diff_currency_account', option.value);
                        }}
                        options={accounts.map((a: AccountInterface) => ({
                            label: a.accountNumber + ' - ' + a.name,
                            value: a.accountNumber
                        }))}
                        error={formik.errors?.eco_diff_currency_account}
                    />

                </Col>
            </Row>
            {/*<Row>
                <Col>
                    <FormGroup switch>
                        <label>Matching invoice?</label>
                        <Input
                            type="switch"
                            // value={formik.values.matching_invoice}
                            checked={formik.values.matching_invoice}
                            onChange={(e) => {
                                formik.setFieldValue('matching_invoice', e.target.checked, false);
                            }}
                        />
                    </FormGroup>
                </Col>

            </Row>*/}

            <Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={() => {
                                setOpen(false)
                            }}
                            content={t("common.button.cancel")}
                        />

                        <QButton
                            type={'primary'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                            content={t("common.button.submit")}
                        />

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BodyModalSettingComponent
