import React, {useCallback, useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {LogInterface} from "../../../interfaces/private/LogInterface";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import {UseMainLayout} from "../../../context/LayoutContext";
import QSearch from "../../../common/components/Genneral/QSearch";
import moment from "moment";
import {config} from "../../../config";
import QHeaderTable from "../../../common/components/Genneral/QHeaderTable";
import {OrderConfig} from "./config";
import QSelectPerPage from "../../../common/components/Genneral/QSelectPerPage";
import {useTranslation} from "react-i18next";
import LogApi from "../../../common/api/functions/Log";

const Order = () => {
    const {t, i18n} = useTranslation();

    const [logChecked, setLogChecked] = useState<any>({
        isCheckAll: false,
        arrOrder: [],
    });

    const [logs, setLogs] = useState<Array<LogInterface>>([]);
    const [log, setLog] = useState<LogInterface | any>({});
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
    })
    const [loading, setLoading] = useState(true);
    UseMainLayout();

    useEffect(() => {

        return () => {

        }
    }, [])

    useEffect(() => {
        if (loading) {
            loadLog().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadLog = async () => {
        try {
            await LogApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    response = response.data.data;
                    setLogs(
                        response?.data || []
                    )
                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="col-md-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Payment Link Logs</div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination}
                                                setLoading={setLoading}/>
                            </div>
                            <QLoading loading={loading}/>
                            <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                <QHeaderTable
                                    paging={pagination}
                                    setPaging={async (p: any) => {
                                        await setPagination(p);
                                        await setLoading(true);
                                    }}
                                    headers={OrderConfig.headers(t)}
                                    // funcChecked={() => {

                                    //     let arrOrder = [];
                                    //     if (!logChecked.isCheckAll) {
                                    //         arrOrder = log?.map((o: LogInterface) => o.id);
                                    //     }

                                    //     setLogChecked({
                                    //         ...logChecked,
                                    //         isCheckAll: !logChecked.isCheckAll,
                                    //         arrOrder: arrOrder,
                                    //     })
                                    // }}
                                />
                                <tbody className="list form-check-all">
                                {logs?.map((log: LogInterface, index: number) => {
                                    // let checkedLogIndex: any | LogInterface = logChecked.arrOrder?.findIndex((o: number) => o === log.id);
                                    return (
                                        <tr key={index}>

                                            <td> {log._id}
                                                {/* <a className={"colo"}>
                                                        <Link to={`${RouteDefine.order}/${log.id}`}>
                                                            {log.id}
                                                        </Link>
                                                    </a> */}
                                            </td>
                                            <td> {log.draftInvoiceNumber}</td>
                                            <td> {log.customerNumber}</td>
                                            <td> {log.oldNumber}</td>
                                            <td> {log.newNumber}</td>
                                            <td> {log.message?.substring(0, 50)}</td>
                                            <td className={'text-center'}>
                                              <span className={{
                                                  success: "text-success",
                                                  failed: "text-danger",
                                                  notice: "text-warning"
                                              }[log.status] || "text-secondary"}>
                                                {log.status}
                                              </span>
                                            </td>
                                            <td>  {log.time_ago}</td>
                                            {/*<td>{(moment(log.created_at)).isValid() && moment(log.created_at).format('DD.MM.YY')}</td>*/}
                                            {/*<td>{(moment(log.updated_at)).isValid() && moment(log.updated_at).format('DD.MM.YY')}</td>*/}
                                            <td className="text-end">
                                                {/* <Link to={`${RouteDefine.order}/${log.id}`}>
                                                        <i className="las la-pen fs-16 cl-br-ams ml-2" />
                                                    </Link> */}

                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default React.memo(Order);
