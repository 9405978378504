import {TableHeaderInterface} from "../../../../interfaces/TableHeaderInterface";

const headers = (t : any) => {
    return [
        // {
        //     label: "",
        //     className: "w-20p",
        //     checkbox: true,
        // },
        {
            label: t("log.id"),
            className: "w-20p"
        },
        {
            label: 'Draft invoice no.',
            className: "w-20p"
        },
        {
            label: 'Customer no.',
            className: "w-20p"
        },
        {
            label: 'Old number',
            className: "w-20p"
        },
        {
            label: 'New number',
            className: "w-20p"
        },
        {
            label: t("log.message"),
            className: "w-20p"
        },

        {
            label: 'status',
            className: "w-20p"
        },

        {
            label: 'created',
            sort: 'created_at',
            className: "w-20p"
        },
        // {
        //     label:'updated at',
        //     sort: 'updated_at',
        //     className: "w-20p"
        // },
    ]
}

export const OrderConfig = {
    headers,
}
