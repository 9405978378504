import React, {useEffect, useState} from "react";
import { Button } from "reactstrap";
import QButton from "../../../../common/components/QButton";
import LedgerApi from "../../../../common/api/functions/Ledger";
import {config} from "../../../../config";

interface Props{
    ledgers:string
    user_id:string
}
const NumberChips: React.FC<Props> =  ({ledgers,user_id}) => {
    const [numbers, setNumbers] = useState<string[]>([]);

    const removeNumber = async (num: string) => {
        let oldNumber = [...numbers];
        try {
            const params = {
                user_id: user_id,
                ledgerId: num
            };
            const response = await LedgerApi.delete(params);
            if (response?.status === config.STATUS_CODE.OK) {
                setNumbers(numbers.filter(n => n !== num));
            }

        } catch (err) {
            console.error("Error loading account:", err);
            setNumbers(oldNumber);
        }
    };

    useEffect(() => {
        let numbers = ledgers?.split(',')
        setNumbers(numbers);
    },[ledgers]);

    return (
        <div style={containerStyles}>
            {numbers?.map(num => (
                <div key={num} style={chipStyles}>
                    {num}
                    <Button
                        close
                        onClick={() => removeNumber(num)}
                        style={closeButtonStyles}
                    />
                </div>
            ))}
        </div>
    );
};

// 🛠 Styles
const containerStyles: React.CSSProperties = {
    display: "flex",
    gap: "4px",
    flexWrap: "wrap",
    padding: "4px",
};

const chipStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    borderRadius: "15px",
    padding: "3px 5px",
    fontSize: "12px",
    fontWeight: "bold",
};

const closeButtonStyles: React.CSSProperties = {
    marginLeft: "4px",
    fontSize: "8px",
    cursor: "pointer",
};

export default NumberChips;
