import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Spinner } from 'reactstrap';
import QLoading from "../QLoading";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    setOpen: Function,
    onClosed?: ()=> void,
    onOpened?: ()=> void,
    headerContent: string|any,
    bodyContent: string|any,
    footerContent?: string|any,
    loading?: boolean|any,
    className?:string|any,
    size?:string|any,
    backdrop?:string|any,
    alwayShow?:boolean|any,
    keyboard?:boolean|any,
}

const QModal: React.FC<Props> = ({
                                     headerContent,
                                     bodyContent,
                                     open,
                                     setOpen,
                                     onClosed = () => {},
                                     onOpened = () => {},
                                     loading = null,
                                     className,
                                     size,
                                     backdrop,
                                     keyboard,
                                     alwayShow=false,
                                 }) => {
    const {t} = useTranslation();
    return (
        <Modal
            isOpen={open}
            onClosed={onClosed}
            onOpened={onOpened}
            toggle={() => { setOpen(!setOpen) }}
            modalClassName={`zoomIn ` + className}
            centered
            size={size}
            backdrop={backdrop}
            keyboard={keyboard}
        >
            { loading !== null ? <QLoading loading={loading} /> : null }
            <ModalHeader
                className={'modal-header border-bottom-ams p-2'}
            >
                <div>
                    { headerContent }
                </div>
                {!alwayShow && (<div>
                    <button type="button" className="btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setOpen(!setOpen);onClosed();}} />
                </div>)}
            </ModalHeader>

            <ModalBody>
                { bodyContent }
            </ModalBody>
        </Modal>
    )
}

export default QModal;
