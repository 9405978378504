import React, {useEffect, useState} from "react";
import {Col, Progress, Row, Table} from "reactstrap";
import moment from "moment/moment";
import {PaymentInterface} from "../../../../interfaces/private/PaymentInterface";

interface Props {
    open: number | boolean, // open > 0 show
    loading: boolean, // open > 0 show
    setOpen: Function,
    setLoading: Function,
    log: PaymentInterface,
}

const BodyModalPaymentDetailComponent: React.FC<Props> = ({log,loading,setLoading,setOpen, open, ...props}) => {
    return (
        <React.Fragment>
            <Row>
                <Table hover>
                    <tbody>
                    <tr><th scope="row">pspReference</th><td>{log.pspReference}</td></tr>
                    <tr><th scope="row">Name</th><td>{log.name}</td></tr>
                    <tr><th scope="row">e-conomic Invoice No.</th><td>{log.eco_invoice_no}</td></tr>
                    <tr><th scope="row">Amount</th><td>{log.amount/100}</td></tr>
                    <tr><th scope="row">MobilePay Message</th><td>{log.message}</td></tr>
                    <tr><th scope="row">Date</th><td>{log.time}</td></tr>
                    <tr><th scope="row">Status</th><td>{log.status}</td></tr>
                    <tr><th scope="row">Message</th><td>{log.data_message??''}</td></tr>
                    <tr><th scope="row">Created at</th><td>{moment(log.created_at).format('hh:mm DD.MM.YY')}</td></tr>
                    <tr>
                        <th scope="row">
                            Data
                        </th>
                        <td>
                            <pre>{JSON.stringify(log.data_log,null,2)}</pre>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Row>

            {/*<Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={() => {
                                setOpen(false)
                            }}
                            content={t("common.button.cancel")}
                        />

                        <QButton
                            type={'primary'}
                            onClick={handleSubmit}
                            content={t("common.button.submit")}
                        />

                    </div>
                </Col>
            </Row>*/}
        </React.Fragment>
    )
}

export default BodyModalPaymentDetailComponent
