import { APIClient } from "../base";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";

const LedgerApi = {
    getAll: async () => await APIClient.get('/ledger', {}),
    assign: async (params:any) => await APIClient.create('/ledger/assign-ledger', params),
    sendNotify: async () => await APIClient.create('/ledger/send-notify', {}),
    delete: async (params:any) => await APIClient.delete('/ledger/delete?' + (new URLSearchParams(params).toString()), {}),
    // update: async (model: SetupInterface) => await APIClient.update('/ledger', model),
}

export default LedgerApi;
