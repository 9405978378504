import React, {useEffect, useState} from "react";
import {config} from "../../../../config";
import {Col, FormGroup, Input, Row} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import {useDispatch} from "react-redux";
import {LedgerInterface, PaymentSetupInterface, SetupInterface} from "../../../../interfaces/private/SetupInterface";
import {useTranslation} from "react-i18next";
import LedgerApi from "../../../../common/api/functions/Ledger";
import QHeaderTable from "../../../../common/components/Genneral/QHeaderTable";
import {PaginationInterface, structPagination} from "../../../../interfaces/PaginationInterface";


interface Props {
    setOpen: Function,
    setLoading: Function,
    userAssign: number|any
}

const BodyModalLedgersComponent: React.FC<Props> = ({setOpen, setLoading,userAssign}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [ledgerChecked, setLedgerChecked] = useState<any>({
        isCheckAll: false,
        arrLedger: [],
    });
    const [ledgers, setLedgers] = useState<LedgerInterface[]>([])
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
        key: 'email',
        sort: 'desc',
    })
    useEffect(() => {
        setLoading(true);
        loadLedgers().then(() => {
            setLoading(false);
        });

    }, [])

    const assignLedger = async () => {
        try {
            const payload = {
                user_id:userAssign,
                ledgers:ledgerChecked.arrLedger
            };
            const response = await LedgerApi.assign(payload);
            if (response?.status === config.STATUS_CODE.OK) {
                setLedgers(
                    response?.data?.data || []
                )
            }
            return response?.data?.data || [];

        } catch (err) {
            console.error("Error loading account:", err);
            return null;
        }finally {
            setOpen(false);
        }
    };

    const loadLedgers = async () => {
        try {
            const response = await LedgerApi.getAll();
            if (response?.status === config.STATUS_CODE.OK) {
                setLedgers(
                    response?.data?.data || []
                )
            }
            return response?.data?.data || [];

        } catch (err) {
            console.error("Error loading account:", err);
            return null;
        }
    }

    return (
        <React.Fragment>
            <Row>
                <table className="table align-middle table-nowrap mb-0" id="customerTable">
                    <QHeaderTable
                        paging={pagination}
                        setPaging={async (p: any) => {
                            await setPagination(p);
                            await setLoading(true);
                        }}
                        headers={[
                            {
                                label: "#",
                                checkbox:true
                            },
                            {
                                label: "Ledger id",
                            },
                            {
                                label: "Currency",
                                className: "w-10p"
                            },
                            {
                                label: "Sale Units",
                                className: "w-10p"
                            },
                        ]}
                        funcChecked={() => {

                            let arrLedger:any = [];
                            if (!ledgerChecked.isCheckAll) {
                                arrLedger = ledgers?.map((u: LedgerInterface) => u.ledgerId);
                            }

                            setLedgerChecked({
                                ...ledgerChecked,
                                isCheckAll: !ledgerChecked.isCheckAll,
                                arrLedger: arrLedger,
                            })
                        }}
                    />
                    <tbody className="list form-check-all">
                    {ledgers?.map((ledger: LedgerInterface, index: number) => {
                        let checkedLedgerIndex: number = ledgerChecked.arrLedger?.findIndex((o: string) => o === ledger.ledgerId) ?? -1;

                        return (
                            <tr key={index}>
                                <td><div className="form-check">
                                    <input
                                        className="form-check-input chk_child"
                                        type="checkbox"
                                        name="chk_child"
                                        // checked={number > -1}
                                        defaultValue="option1"
                                        onClick={(event: any) => {
                                            if (checkedLedgerIndex > -1) {
                                                let clone = { ...ledgerChecked };
                                                clone.arrLedger.splice(checkedLedgerIndex, 1);
                                                setLedgerChecked(clone)
                                            } else {
                                                let clone = { ...ledgerChecked };
                                                clone.arrLedger.push(ledger);
                                                setLedgerChecked(clone)
                                            }
                                        }}
                                    />
                                </div></td>
                                <td>{ledger.ledgerId}</td>
                                <td>{ledger.currency}</td>
                                <td>{ledger.salesUnits?.[0]?.name || 'N/A'}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </Row>
            <br />
            <Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={() => {
                                setOpen(false)
                            }}
                            content={t("common.button.cancel")}
                        />

                        <QButton
                            type={'primary'}
                            onClick={() => {
                                // formik.handleSubmit()
                                assignLedger()
                            }}
                            content={t("common.button.submit")}
                        />

                    </div>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default BodyModalLedgersComponent
