
const headers = (t : any) => {
    return [
        // {
        //     label: "",
        //     className: "w-20p",
        //     checkbox: true,
        // },
        // {
        //     label: 'id',
        //     sort: 'id',
        //     className: "w-20p"
        // },
        {
            label: 'Vipps pspReference',
            className: "w-10p"
        },
        {
            label: 'Vipps Customer Name',
            className: "w-20p"
        },      {
            label: 'Vipps Message',
            className: "w-20p"
        },

        {
            label: 'Vipps Amount',
            className: "w-20p"
        },
        {
            label: 'Vipps Date',
            className: "w-20p"
        },
        {
            label: 'e-co Inv No.',
            className: "w-20p"
        },
        {
            label: 'Sync message',
            className: "w-20p"
        },
        {
            label: 'status',
            className: "w-20p"
        },


        {
            label: 'Created',
            sort: 'created_at',
            className: "w-20p"
        },
    ]
}

export const PaymentConfig = {
    headers,
}
