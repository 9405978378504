export const RouteDefine = {
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',

    setup: '/setup',
    login: '/login',
    order: '/payment-link',
    payment: '/payment',
    admin: '/admin',
    dashboard: '/dashboard',
    document: '/document',
    log: '/log',

    userUpdate: '/user',
    userDetail: '/user/business-detail',
    business: '/business',
    businessUpdate: '/business-update',
    mobilePay:'/mobile-pay',
    economic: '/economic',
    confirm: '/confirm',
    register: '/register',
    config: '/config',

    forbidden: '/403',
    notFound: '/404',
}
