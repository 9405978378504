import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

const PaymentApi = {
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/payment', pagination),
    getDetail: async (id: number | string | any) => await APIClient.get('/payment/' + id, {}),
    syncPayment: async (params?:any) => await APIClient.create('/payment/sync', params),
}

export default PaymentApi;
