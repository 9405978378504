import {RouteInterface} from "../interfaces/RouteInterface";
import { EconomicMarket } from "../features/public/EconomicMarket/EconomicMarket";
import Setup from "../features/public/Setup";
import Document from "../features/private/Document";
import { Confirm } from "../features/public/Confirm/Confirm";
import { Config } from "../features/public/Config/Config";
export const publicRoute: Array<RouteInterface> = [

    {path: '/economic-market', Component: EconomicMarket},
    {path: '/confirm', Component: Confirm},
    {path: '/setup', Component: Setup},
    {path: '/config', Component: Config},
    {path: '/document', Component: Document},

]
