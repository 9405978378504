import React, {useState} from "react";
import {CSSProperties} from "react";
import QButton from "../../../../common/components/QButton";
import LedgerApi from "../../../../common/api/functions/Ledger";
import QAlert from "../../../../common/components/Genneral/QAlert";

interface Props {
    open: boolean | any
}

const ContentOverlayComponent: React.FC<Props> = ({open}) => {
    const [sending, setSending] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const sendNotify = () => {
        setSending(true);
        LedgerApi.sendNotify().then(() => {
            setSending(false);
            setSendSuccess(true);
        });
    };

    return (
        open && (<div style={overlayStyles}>
            <div style={modalStyles}>
                <h4>Steps to Enable MobilePay Payment </h4>
                <p></p>
                <p>To use the MobilePay Payment feature, please follow the instructions at the <a className={'text-danger'} href={'/document#grantAccessVippsMobilePay'} target={'_blank'}>link here</a>.</p>
                <p>Once you've completed the steps, click the button <span className={'text-danger'}>"Notify Us"</span> below.</p>
                <p>We will receive a notification and proceed with the setup accordingly.</p>
                <p>
                    <QButton
                        type={'primary'}
                        onClick={() => {
                            sendNotify();
                        }}
                        content={sending ? 'Sending...' : 'Notify Us'}
                    />
                </p>
                {sendSuccess && (<div className={`alert alert-success mb-xl-0`} role="alert">Your notification has been successfully sent
                    to the admin. Please wait for further review'
                </div>)}
                {/*<Button color="primary" onClick={() => setShowOverlay(false)}>OK</Button>*/}
            </div>
        </div>)
    );
};
const containerStyles: CSSProperties = {
    position: "relative", // Để overlay bám theo phần này, không phủ cả trang
    width: "50%", // Ví dụ chỉ che 50% trang
    margin: "50px auto", // Canh giữa
    border: "1px solid #ccc",
    padding: "20px",
};

const overlayStyles: CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
};

const modalStyles: CSSProperties = {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
};
export default ContentOverlayComponent;
