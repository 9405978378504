import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import {Dashboard} from "../features/private/Dashboard";
import {Business} from "../features/private/Business";
import {Economic} from "../features/private/Economic";
import { MobilePay } from "../features/private/MobilePay";
import Payment from "../features/private/Payment";
import order from "../features/private/Order";
// import Setup from "../features/public/Setup";

export const privateRoute: Array<RouteInterface> = [
    {path: RouteDefine.dashboard, Component: Dashboard},
    {path: RouteDefine.payment, Component: Payment},
    {path: RouteDefine.order, Component: order},
    // {path: RouteDefine.setup, Component: Setup},

    {path: RouteDefine.business, Component: Business},
    {path: RouteDefine.economic, Component: Economic},
    {path: RouteDefine.mobilePay, Component: MobilePay},
    // {path: RouteDefine.document, Component: Document},
]
